import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import OGP from '../../static/og-image.jpg';

export const Seo = (props) => {
  const { pathname } = useLocation();
  const { title, description, image, lang, meta = [], ...restProps } = props;

  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{ lang: 'ja' }}
      title={title || siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: description || siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title || siteMetadata.title,
        },
        {
          property: `og:description`,
          content: description || siteMetadata.description,
        },
        {
          property: 'og:image',
          content: image || OGP,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:site_name',
          content: siteMetadata.title,
        },
        {
          property: 'og:locale',
          content: 'ja_JP',
        },
        {
          property: 'og:url',
          content: siteMetadata.siteUrl + pathname,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: `twitter:creator`,
          content: ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description || siteMetadata.description,
        },
        ...meta,
      ]}
      {...restProps}
    />
  );
};

export default Seo;
